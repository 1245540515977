/* eslint-disable no-magic-numbers */
import { createSelector } from 'reselect';
import { selectedLocationSelector } from './location';

const AVAILABLE_LOCATIONS_IDS = [
    4058659,    // Delhi
    4058997,    // Mumbai
    4059014,    // Pune
    4059162,    // Chennai
    4058526,    // Hyderabad
    4058748,    // Gurgaon
    4387779,    // Faridabad
    4059326,    // Noida
    4059280,    // Ghaziabad
    4059005,    // Navi Mumbai
    4059026,    // Thane
    5461408,    // Kanchipuram
    1000000000001575,   // Old Mahabalipuram Road
    5313531,    // Anekal
    4058803,    // Bengaluru
    4059164,    // Coimbatore
    4058841,    // Mysuru
    4058916,    // Indore
    4059123,    // Jaipur
    4059002,    // Nashik
    4058677,    // Ahmedabad
    4157275     // Kolkata
];

export const showCMCBannerSelector = createSelector(
    selectedLocationSelector,
    (_, { cookies }) => cookies.get('laquesis'),
    (selectedLocation, featureCookie) => {
        if (featureCookie && !featureCookie.includes('pan-30399')) {
            return false;
        }

        const { id, addressComponents } = selectedLocation || {};

        if (id && AVAILABLE_LOCATIONS_IDS.includes(id)) {
            return true;
        }

        const hasAvailableLocationInAddressComponent = addressComponents
            && !!(addressComponents.find(
                ({ id }) => AVAILABLE_LOCATIONS_IDS.includes(id)
            ));

        if (hasAvailableLocationInAddressComponent) {
            return true;
        }

        return false;
    }
);

const valuesToObj = value => ({ value, displayValue: value });
const EMPTY_OPTION = ['Select an option'].map(valuesToObj);

export const makeGetSelectOptions = () => createSelector(
    (_, { globalsConfig }) => globalsConfig.get('cashmycar'),
    (state, { name }) => {
        const fieldData = state.bannerCMC.form[name];

        return fieldData
            ? [...fieldData.list]
            : [];
    },
    (_, { name }) => name,
    (_, { value }) => value,
    (cashmycarConfig, options, name, value) => {
        const MILEAGE_DATA = cashmycarConfig.mileage;
        const LOCATIONS_DATA = cashmycarConfig.locations.map(valuesToObj);
        const CAR_CITIES = cashmycarConfig.carCities.map(valuesToObj);

        if (name === 'mileage') {
            return value
                ? [...MILEAGE_DATA]
                : [...EMPTY_OPTION, ...MILEAGE_DATA];
        }

        if (name === 'location') {
            return value
                ? [...LOCATIONS_DATA]
                : [...EMPTY_OPTION, ...LOCATIONS_DATA];
        }

        if (name === 'car_city') {
            return value
                ? [...CAR_CITIES]
                : [...EMPTY_OPTION, ...CAR_CITIES];
        }

        return value
            ? options.map(valuesToObj)
            : [...EMPTY_OPTION, ...options.map(valuesToObj)];
    }
);

export const makeGetSelectFeaturedOptions = () => createSelector(
    (state, { name }) => {
        const fieldData = state.bannerCMC.form[name];

        return fieldData && fieldData.featuredList
            ? fieldData.featuredList
            : null;
    },
    options => options && [...EMPTY_OPTION, ...options.map(valuesToObj)]
);

export const getCarDescription = state => {
    const { year, make, model } = state.bannerCMC.userData;

    return `${year} ${make} ${model}`;
};

export const hasQuotation = state => {
    const quote = state.bannerCMC.form.quote.quote;

    return quote
        ? quote[0] > 0
        : false;
};

export const getCarQuotation = createSelector(
    hasQuotation,
    state => state.bannerCMC.form.quote.quote,
    (hasQuotation, quote) => {
        const result = {
            min: 0,
            max: 0
        };

        if (hasQuotation) {
            result.min = quote[0];
            result.max = quote[1];
        }

        return result;
    }
);
