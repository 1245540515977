/* global window */
import React, { Component, createRef } from 'react';
import css from './Listing.scss';
import cssLamudi from 'Components/LamudiBranding/LamudiBranding.scss';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import Button from 'Components/Button/Button';
import classNames from 'classnames';
import LoadMoreObserver from 'Components/LoadMoreObserver/LoadMoreObserver';
import LamudiBranding from 'Components/LamudiBranding/LamudiBranding';
import { HEADER_OFFSET } from 'Constants/items';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { showLamudiBranding } from 'Helpers/lamudiHelper';
import { isMobile } from 'Helpers/devices';
import { LAMUDI_LISTING_BANNER_DESKTOP } from 'Constants/imageUrls';
import checkIfNonHeroCategories from './helper';

export class Listing extends Component {
    static propTypes = {
        items: PropTypes.array,
        itemsLength: PropTypes.number,
        onLoadNextPage: PropTypes.func,
        isFetching: PropTypes.bool,
        className: PropTypes.string,
        header: PropTypes.element,
        page: PropTypes.number,
        showLoadMore: PropTypes.bool,
        similarAdsData: PropTypes.array,
        similarAdsCount: PropTypes.number,
        renderPagination: PropTypes.func,
        children: PropTypes.node,
        renderBanners: PropTypes.func,
        adIdsToUpdate: PropTypes.array,
        autoScroll: PropTypes.bool,
        categoryID: PropTypes.string,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired
    };

    static defaultProps = {
        items: [],
        itemsLength: 0,
        renderPagination: null,
        similarAdsCount: 0,
        similarAdsData: [],
        onLoadNextPage: () => {},
        isFetching: false,
        className: '',
        page: 1,
        renderBanners: () => {},
        adIdsToUpdate: [],
        autoScroll: false
    };

    constructor(props) {
        super(props);

        this.loadMoreRef = createRef();
        this.scrollPos = null;
        this.isLoadMoreClicked = false;
        this.showLoadMore = (props.showLoadMore && !props.similarAdsData?.length) || (props.showLoadMore && !!props.similarAdsCount);
        this.isNonHeroCategories = checkIfNonHeroCategories(props.categoryID);
    }

    componentDidUpdate(prevProps, __, snapshot) {
        if (snapshot !== null) {
            window.scrollTo({
                top: snapshot - HEADER_OFFSET,
                left: 0
            });
        }
        if (prevProps.showLoadMore !== this.props.showLoadMore) {
            this.showLoadMore = (this.props.showLoadMore && !this.props.similarAdsData?.length) || (this.props.showLoadMore && !!this.props.similarAdsCount);
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (this.props.isFetching && this.isLoadMoreClicked && prevProps.itemsLength !== this.props.itemsLength) {
            const loadMoreBtn = this.loadMoreRef.current;

            this.scrollPos = loadMoreBtn?.offsetTop;
        }
        else if (!this.props.isFetching && this.isLoadMoreClicked && prevProps.itemsLength < this.props.itemsLength && this.scrollPos !== null) {
            const scrollPos = this.scrollPos;

            this.scrollPos = null;
            this.isLoadMoreClicked = false;

            return scrollPos;
        }
        return null;
    }

    loadMore = () => {
        const { isFetching, onLoadNextPage } = this.props;

        this.isLoadMoreClicked = true;
        return isFetching ? null : onLoadNextPage();
    };

    renderLoadMore = () => {
        const { items, isFetching, page } = this.props;
        const shouldShow = (typeof this.showLoadMore === 'undefined' && isFinite(page))
            ? (items.total > (Number(page) * items.length))
            : this.showLoadMore;

        return shouldShow && (
            <div ref={ this.loadMoreRef } className={ classNames(css.loadMore, { [css.loading]: isFetching }) }>
                <Button
                    type="secondaryBtn"
                    data-aut-id="btnLoadMore"
                    onClick={ this.loadMore }
                >
                    <Translation id="loadMore" />
                </Button>
            </div>
        );
    }

    renderAutoScroll = () => {
        const { autoScroll, isFetching, page, onLoadNextPage } = this.props;
        const shouldAddObserver = autoScroll && this.showLoadMore && !isFetching;

        return shouldAddObserver ? <LoadMoreObserver key={ `loadMore-${page}` } loadMore={ onLoadNextPage } /> : null;
    }

    render() {
        const { className, header, renderBanners, adIdsToUpdate, renderPagination, autoScroll, marketConfig, categoryID, showLoadMore } = this.props;
        const listingBannerRedirectionUrl = marketConfig.get('lamudiBranding', 'listingBannerRedirectionUrl');

        return (
            <div className={ className }>
                { !isMobile && showLamudiBranding(marketConfig, categoryID)
                    && (<LamudiBranding assetUrl={ LAMUDI_LISTING_BANNER_DESKTOP } redirectionUrl={ listingBannerRedirectionUrl } cssClass={ cssLamudi.listingBanner } />) }
                { this.isNonHeroCategories && renderBanners('sticky', adIdsToUpdate, classNames(css['sticky-banner'], { [css.mobile]: isMobile })) }

                { header }
                <div>
                    { React.Children.map(this.props.children, c => React.cloneElement(c, {
                        loadMoreBtn: (!autoScroll && !renderPagination) && (showLoadMore) ? this.renderLoadMore() : null
                    }))}
                </div>
                { autoScroll ? this.renderAutoScroll() : (renderPagination && renderPagination() || null)}
                { renderBanners('bottom', adIdsToUpdate) }
            </div>
        );
    }
}

export default withConfig('marketConfig')(Listing);
