import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css as uicss } from 'panamera-react-ui';
import classNames from 'classnames';
import NoResults from 'Components/NoResults/NoResults';
import BannerImage from 'Components/BannerImage/BannerImage';
import css from '../Home.desktop.scss';
import Listing from './HomeListing';
import { FormattedMessage as Translation } from 'react-intl';
import HaTS from 'Components/HaTS/HaTS.APP_TARGET';
import { userIsLogged } from 'Selectors/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showCMCBannerSelector } from 'Selectors/bannerCMC';
import withCookies from 'HOCs/withCookies/withCookies';
import { HOME } from 'Constants/tracking';
import withConfig from 'HOCs/withConfig/withConfig';
import AsyncAutosHomePageWidgets from '../../../olxautos/components/AutosHomePageWidgets/AsyncAutosHomePageWidgets';
import { EvaluationConstants } from 'olx-autos-landing-page';
import SITE_CODES from 'Constants/siteCodes';
import { HOME_BANNER_DESKTOP_SELL } from 'Constants/widgets';
import { configSelector } from 'Selectors/config';
import { BANNER_CAROUSEL_WIDGETS } from '../../../pages/Listing/ListingOld/ListingPageContent/Constant';
import { getShowBannerCarousel, getCarouselHomePageData } from 'Selectors/carousel';
import CookieManager from 'Helpers/cookies';
import { EXPIRATION_DATE, RESET_COOKIE_VALUE_LIMIT } from '../../../constants/carousel';
import AsyncNewsCarousel from '../../../olxautos/components/AutosHomePageWidgets/AsyncNewsCarousel';

const { grid } = uicss;

export const HomeContent = ({
    items,
    isFetchingItems,
    onViewItem,
    location,
    enabledRealImpressions,
    onLoadNextPage,
    renderBanners,
    feedsTitle,
    adIdsToUpdate,
    showLoadMore,
    hatsAvailable,
    userIsLogged,
    offline,
    extraTrackAttr,
    config,
    cookies,
    handleIconClick,
    carouselHomePage,
    dataTestId
}) => {
    const title = useMemo(() => <Translation id="HaTSTitle" />, []);
    const cancelButton = useMemo(() => <Translation id="HaTSCancelButton" />, []);
    const acceptButton = useMemo(() => <Translation id="HaTSAcceptButton" />, []);
    const { SELLER_STATE_COOKIE, EVALUATION_STATES } = EvaluationConstants;
    const seller_state = cookies.get(SELLER_STATE_COOKIE);
    const siteCode = config.get('siteCode');

    const cookiehomePageBanner = CookieManager.readCookie('home_page_banner');

    useEffect(() => {
        if (!cookiehomePageBanner || (cookiehomePageBanner && RESET_COOKIE_VALUE_LIMIT === Number(cookiehomePageBanner))) {
            CookieManager.createCookie('home_page_banner', 1, EXPIRATION_DATE);
        }
        else {
            CookieManager.createCookie('home_page_banner', Number(cookiehomePageBanner) + 1, EXPIRATION_DATE);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const autosHomeEvalutaionState = useMemo(() => (
        [
            EVALUATION_STATES.VALUATION_DONE,
            EVALUATION_STATES.APPOINTMENT_CREATED
        ].includes(seller_state)
        && [
            SITE_CODES.OLX_CO,
            SITE_CODES.OLX_ID
        ].includes(siteCode)
    ), [seller_state, siteCode, EVALUATION_STATES.VALUATION_DONE, EVALUATION_STATES.APPOINTMENT_CREATED]);

    const showAutosHomePageWidgets = useMemo(() => (
        (!!config.get('showAutosHomePageWidget') && !(siteCode === SITE_CODES.OLX_ID)) || autosHomeEvalutaionState
    ), [config, siteCode, autosHomeEvalutaionState]);

    const autosInitialState = useMemo(() => EVALUATION_STATES.INITIAL === seller_state || !seller_state, [seller_state, EVALUATION_STATES.INITIAL]);

    const getHomePageBanner = useCallback((showAutosHomePageWidgets, offline) => {
        if (showAutosHomePageWidgets) {
            if (autosInitialState && [SITE_CODES.OLX_IN, SITE_CODES.OLX_TR, SITE_CODES.OLX_CO].includes(siteCode)) {
                return (
                    <BannerImage
                        offline={ offline }
                        frmPage={ HOME }
                        widgetToFetch={ HOME_BANNER_DESKTOP_SELL } />
                );
            }
            return (
                <div className={ classNames(css.autosBannerWidget) }>
                    <AsyncAutosHomePageWidgets handleIconClick={ handleIconClick } />
                </div>
            );
        }

        return (
            <div className={ classNames(css.autosBannerWidgetCarousel) }>
                <AsyncAutosHomePageWidgets handleIconClick={ handleIconClick } />
            </div>
        );
    }, [autosInitialState, handleIconClick, siteCode]);

    return (
        <section className={ css.home } data-test-id={ dataTestId }>
            {hatsAvailable && userIsLogged && (
                <HaTS
                    hatsTitle={ title }
                    hatsCancelButton={ cancelButton }
                    hatsAcceptButton={ acceptButton }
                />
            )}
            {
                (carouselHomePage?.enabled
                    && carouselHomePage?.widgetName?.pwa_desktop
                    && (Number(cookiehomePageBanner) % Number(carouselHomePage.modulus_lhs_value)) === Number(carouselHomePage.modulus_rhs_value))
                    ? <BannerImage
                        listingPageCarousel={ true }
                        widgetToFetch={ carouselHomePage?.widgetName?.pwa_desktop }
                        frmPage={ HOME } />
                    : getHomePageBanner(showAutosHomePageWidgets, offline)
            }

            <div className={ grid.row }>
                {renderBanners('top', adIdsToUpdate)}
                {renderBanners('right', adIdsToUpdate)}
                <div
                    className={ classNames(
                        grid.col,
                        grid.marginContainer,
                        grid.xl12,
                        grid.l12,
                        grid.m12,
                        grid.s12,
                        css.bannersContainer
                    ) }
                >
                    {!isFetchingItems && !items.length && <NoResults />}
                    {(isFetchingItems || items.length !== 0) && (
                        <Listing
                            onLoadNextPage={ onLoadNextPage }
                            header={ feedsTitle }
                            showLoadMore={ showLoadMore }
                            items={ items }
                            isFetching={ isFetchingItems }
                            enabledRealImpressions={ enabledRealImpressions }
                            onViewItem={ onViewItem }
                            location={ location }
                            visualizationType="grid"
                            itemPostPlaceholderOrigin="home"
                            fourColumns
                            extraTrackAttr={ extraTrackAttr }
                        />
                    )}
                    <div data-aut-id="desktop-news-section"><AsyncNewsCarousel /></div>
                    {renderBanners('bottom', adIdsToUpdate)}
                </div>
            </div>
        </section>
    );
};

HomeContent.propTypes = {
    items: PropTypes.array,
    isFetchingItems: PropTypes.bool,
    onViewItem: PropTypes.func,
    location: PropTypes.object,
    enabledRealImpressions: PropTypes.bool,
    onLoadNextPage: PropTypes.func,
    renderBanners: PropTypes.func,
    adIdsToUpdate: PropTypes.array,
    feedsTitle: PropTypes.node,
    showLoadMore: PropTypes.bool,
    userIsLogged: PropTypes.bool.isRequired,
    hatsAvailable: PropTypes.bool,
    offline: PropTypes.bool,
    extraTrackAttr: PropTypes.object.isRequired,
    config: PropTypes.shape({
        get: PropTypes.func
    }),
    cookies: PropTypes.shape({
        get: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired
    }),
    handleIconClick: PropTypes.func,
    carouselHomePage: PropTypes.object,
    dataTestId: PropTypes.string
};

HomeContent.defaultProps = {
    items: [],
    renderBanners: () => {},
    adIdsToUpdate: [],
    offline: false
};

export const mapStateToProps = (state, ownProps) => {
    const showBannerCarouselforHomePage = getShowBannerCarousel(configSelector(state)?.features, BANNER_CAROUSEL_WIDGETS.SHOW_BANNER_CAROUSEL_FOR_CATEGORY);
    const carouselHomePage = getCarouselHomePageData(showBannerCarouselforHomePage);

    return {
        userIsLogged: userIsLogged(state),
        hatsAvailable: state.hats.hatsAvailable,
        showCMCBanner: showCMCBannerSelector(state, ownProps),
        carouselHomePage
    };
};

export default compose(
    withCookies,
    withConfig,
    connect(mapStateToProps)
)(React.memo(HomeContent));
