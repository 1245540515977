import lazyLoad from 'Components/asyncLoading/lazyLoad';
import LoadingPlaceholder from './LoadingPlaceholder';

const AsyncNewsCarousel = lazyLoad({
    loader: () => import(
        /* webpackChunkName: "home-page-widgets" */
        /* webpackPreload: true */
        './NewsCarousel'),
    loading: LoadingPlaceholder
});

export default AsyncNewsCarousel;
