// const PROPERTY_CATEGORIES = ['88', '4827', '4833', '5154', '5156', '5158', '5160'];
// const CARS_CATEGORIES = [
//     '86',
//     '198',
//     '4760',
//     '4762',
//     '4759',
//     '4761',
//     '4662'
// ];
const NON_HERO_CATEGORIES = [
    '86',
    '87',
    '89',
    '90',
    '92',
    '94',
    '96',
    '97',
    '98',
    '198',
    '200',
    '202',
    '203',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '226',
    '227',
    '228',
    '229',
    '230',
    '231',
    '232',
    '233',
    '234',
    '235',
    '4662',
    '4760',
    '4762',
    '4759',
    '4761',
    '4822',
    '4823',
    '4824',
    '4835',
    '4836',
    '4841',
    '4842',
    '4843',
    '4844',
    '4845',
    '4846',
    '4852',
    '4853',
    '4952',
    '4964',
    '4975',
    '5046',
    '5047',
    '5048',
    '5049',
    '5053',
    '5090',
    '5095',
    '5123',
    '5124',
    '5126',
    '5142'
];

const checkIfNonHeroCategories = categoryId => {
    return [...NON_HERO_CATEGORIES].includes(categoryId);
};

export default checkIfNonHeroCategories;
