const getShowBannerCarousel = (showBannerCarouselMobileCategory, bannerCaraouselcategory) => {
    return showBannerCarouselMobileCategory && showBannerCarouselMobileCategory.length > 0 && showBannerCarouselMobileCategory.find(value => {
        return value?.name === bannerCaraouselcategory && value?.enabled === true;
    });
};
const getCarouselCategoryData = (showBannerCarouselforCategory, categoryId) => {
    return showBannerCarouselforCategory?.data?.categoryData?.length > 0 && showBannerCarouselforCategory?.data?.categoryData.find(value => {
        return value?.categoryId == categoryId;
    });
};

const getCarouselHomePageData = showBannerCarouselforHomePage => {
    return showBannerCarouselforHomePage?.data?.homePageData;
};

export {
    getShowBannerCarousel,
    getCarouselCategoryData,
    getCarouselHomePageData
};
